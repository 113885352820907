<!--
 * @Author: your name
 * @Date: 2021-12-07 15:40:36
 * @LastEditTime: 2023-02-10 15:56:45
 * @LastEditors: 韩鸿 1014315067@qq.com
 * @Description: 渠道合作
 * @FilePath: \new-website-1123\src\views\service\cooperation\index.vue
-->
<template>
  <div class="service-cooperation">
    <!--banner-->
    <div
      class="contactus-us"
      :style="{
        backgroundImage: 'url(' + banner + ')',
        'background-size': 'cover',
      }"
    >
      <div class="contactus-us-view">
        <div class="contactus-us-infor">
          <h2>成为合作伙伴</h2>
          <h4>提升传统作业效率，开启智慧农业新时代!</h4>
        </div>
      </div>
    </div>

    <!--提交-->
    <div class="cooperation-Subup">
      <div class="cooperation-form">
        <div class="flexbox">
          <div class="cooperation-item">
            <h3 class="public-title">申请合作类型</h3>
            <el-select class="public-select" style="width: 480px;"   placeholder="选择合作类型"  v-model="cooperation.type">
              <el-option value="经销商">经销商</el-option>
              <el-option value="加盟商">加盟商</el-option>
            </el-select>
            <h3 class="public-title">申请合作产品类型</h3>
            <el-select class="public-select " style="width: 480px;" multiple @change="onSelectChange"  placeholder="选择合作产品类型" v-model="productType">
              <el-option v-for="(item,index) in cooperationType" :key="index" :label="item.label" :value="item.value"></el-option>
            </el-select>
            <h3 class="public-title">企业基本信息</h3>
            <input
              type="text"
              placeholder="公司注册名称"
              v-model="cooperation.companyName"
              class="public-input"
            />
            <input
              type="text"
              placeholder="公司主营业务"
              v-model="cooperation.companyBusiness"
              class="public-input"
            />
            <input
              type="text"
              placeholder="经营年限"
              v-model="cooperation.companyOperatingYears"
              class="public-input"
            />
            <input
              type="text"
              placeholder="年营收额/万元"
              v-model="cooperation.companyAnnualIncome"
              class="public-input"
            />
            <input
              type="text"
              placeholder="经营门店数量"
              v-model="cooperation.companyStoreNum"
              class="public-input"
            />
          </div>
          <div class="cooperation-item">
            <h3 class="public-title">申请人信息</h3>
            <input
              type="text"
              placeholder="*姓名"
             v-model="cooperation.name"
              class="public-input"
            />
            <input
              type="text"
              placeholder="职位"
             v-model="cooperation.position"
              class="public-input"
            />
            <input
              type="text"
              placeholder="*联系电话"
             v-model="cooperation.phone"
              class="public-input"
            />
            <div class="cooperation-item " >
              <h3 class="public-title">意向区域</h3>
            <el-select class="public-select " placeholder="省份" v-model="cooperation.provinceName" @change="onChange">
              <el-option v-for="item in provinceNameList" :key="item.id"
              :label="item.name"
               :value="item.name"
              ></el-option>
            </el-select>

            <el-select class="public-select " placeholder="城市" v-model="cooperation.cityName" @change="onCountyChange">
              <el-option v-for="item in countyNameList" :key="item.id"
              :label="item.name"
               :value="item.name"></el-option>
            </el-select>

            <el-select class="public-select  " placeholder="区县" v-model="cooperation.countyName">
              <el-option v-for="item in cityNameList" :key="item.id"
              :label="item.name"
               :value="item.name"></el-option>
            </el-select>
            <h3 class="public-title">备注</h3>
            <textarea class="public-textarea" v-model="cooperation.remark"></textarea>
            </div>
          </div>
        </div>

        <div class="tips-demo">
          <span>*</span>请确保您填写的是真实信息，我们会在24小时内与您联系。
        </div>

        <div class="cooperation-button animation-3" @click="addCooperation">提交</div>
      </div>
    </div>
  </div>
</template>

<script>
import {getAreaApi,addCooperationApi} from '@/api/index.js'
export default {
  name: "service-cooperation",
  components: {},
  data() {
    return {
      banner: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/service/cooperation-pic.jpg",
      cooperation:{
        type:'',//合作类型
        phone:'',//申请人电话
        name:'',//申请人姓名
        position:'',//申请人职位
        productType:'',//合作产品类型
        provinceName:'',// 意向省份名称
        cityName:'',//意向区县名称
        countyName:'',//意向城市名称
        companyName:'',//公司注册名称
        companyBusiness:'',//公司主营业务
        companyOperatingYears:'',//经营年限
        companyAnnualIncome:'',//年营收额
        companyStoreNum:'',//经营门店数量
        remark:'',//备注
      },
      provinceNameList:[],
      countyNameList:[],
      cityNameList:[],
      cooperationType:[
        {
          label:'智慧农业云平台',
          value:'智慧农业云平台'
        },
        {
          label:'虫情测报仪',
          value:'虫情测报仪'
        },
        {
          label:'气象测报站',
          value:'气象测报站'
        },
        {
          label:'水肥机一体化',
          value:'水肥机一体化'
        },
        {
          label:'土壤墒情仪',
          value:'土壤墒情仪'
        },
        {
          label:'智能孢子仪',
          value:'智能孢子仪'
        },
        {
          label:'太阳能捕虫器',
          value:'太阳能捕虫器'
        },
        {
          label:'农业无人车',
          value:'农业无人车'
        },
        {
          label:'农业无人机',
          value:'农业无人机'
        },
      ],
      productType:''
    };
  },
  created() {this.getAreaTree()},
  computed: {
    currentDevice() {
      return this.deviceList.filter((item) => item.key == this.deviceActive)[0];
    },
  },
  methods: {
    addCooperation(){
      if(Object.values(this.cooperation).every(el=> el=='')){
        this.$message({
          message: '请填写完整信息',
          type: 'warning',
          duration: 1000
        });
        return
      }
      addCooperationApi(this.cooperation).then(res=>{
            if(res.data.code==200){
                this.$message({
                message: '合作申请提交成功',
                type: 'success',
                duration: 2000
              });
            }else {
              this.$message({
                message: '合作申请提交失败 请刷新后重新!',
                type: 'warning',
                duration: 2000
              });
            }
            this.reset()
      })
    },
    getAreaTree(){
      getAreaApi().then(res=>{
        this.provinceNameList=res.data.data
      })
    },
    onChange(val){
      this.countyNameList=this.provinceNameList.filter(item => item.name ===val)[0].sonList
      this.cooperation.countyName = ''
      this.cooperation.cityName = ''
    },
    onCountyChange(val){
      this.cityNameList=this.countyNameList.filter(item => item.name ===val)[0].sonList
      this.cooperation.countyName = ''
    },
     reset(){
      this.cooperation = {
        type:'',//合作类型
        phone:'',//申请人电话
        name:'',//申请人姓名
        position:'',//申请人职位
        productType:'',//合作产品类型
        provinceName:'',// 意向省份名称
        cityName:'',//意向城市名称
        countyName:'',//意向区县名称
        companyName:'',//公司注册名称
        companyBusiness:'',//公司主营业务
        companyOperatingYears:'',//经营年限
        companyAnnualIncome:'',//年营收额
        companyStoreNum:'',//经营门店数量
        remark:''
      }
      this.productType = ''
    },
    onSelectChange(val){
      val = val.join('-')
      this.cooperation.productType = val
    }
  }
};
</script>

<style lang="scss" scoped>
.contactus-us {
  height: 720px;

  .contactus-us-view {
    width: 1200px;
    padding-top: 196px;
    margin: 0 auto;
    .contactus-us-infor {
      color: $color-text;
      text-align: center;
      h2 {
        font-size: 72px;
        margin-bottom: 42px;
      }
      h4 {
        font-size: 28px;
        margin-bottom: 0;
        font-weight: normal;
      }
    }
  }
}

.cooperation-Subup {
  padding: 60px 0 80px;

  .cooperation-form {
    height: 855px;
    width: 1500px;
    justify-content: center;
    align-items: start;
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.05);
    .flexbox{
      display: flex;
      justify-content: center;
      align-items: flex-start;

    }
    .cooperation-button {
      width: 148px;
      height: 50px;
      margin: 0 auto;
      background: #1dbf73;
      line-height: 50px;
      text-align: center;
      color: $color-text;
      border-radius: 6px;
      margin-top: 60px;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
    .cooperation-item {
      width: 480px;
      padding-top: 30px;
      margin-left: 32px;
      margin-right: 32px;
      .cooperation-item {
        padding-top: 0;
        margin-left: 0;
        h3:nth-child(1) {
          padding: 0;
        }
      }
      .mt50 {
        transform: translateY(-50px);
      }
      .mb0 {
        margin-bottom: 0;
      }
      .public-title {
        font-size: 18px;
        color: $color-active;
        margin-bottom: 20px;
        padding-top: 20px;
      }
      .public-select {
        width: 150px;
      }
      .public-textarea {
        width: 480px;
        height: 160px;
        border: 1px solid rgba(230, 230, 230, 0.1);
        line-height: 24px;
        background: transparent;
        color: #fff;
        font-size: 14px;
        padding: 18px 22px;
        border-radius: 6px;
        margin-bottom: 20px;
        resize: none;
        &:focus-visible {
          outline: 1px solid #1dbf73;
        }
      }
      .public-input {
        width: 480px;
        height: 40px;
        line-height: 40px;
        border: 1px solid rgba(230, 230, 230, 0.1);
        background: transparent;
        color: #fff;
        font-size: 14px;
        padding-left: 16px;
        border-radius: 6px;
        margin-bottom: 20px;
        &:focus-visible {
          outline: 1px solid #1dbf73;
        }

        &::-webkit-input-placeholder {
          /* WebKit browsers */
          color: #666;
        }
        &:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: #666;
        }
        &::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #666;
        }
        &:-ms-input-placeholder {
          /* Internet Explorer 10+ */
          color: #666;
        }
      }
    }
  }
}
::v-deep .el-input--suffix .el-input__inner{
  background-color: #222222;
  border-color: rgba(230, 230, 230, 0.1);
  color: #fff;
}
::v-deep  .el-select .el-input.is-focus .el-input__inner{
  border-color: #1dbf73;
}
::v-deep .el-input--suffix .el-input__inner::placeholder{
  color: #666;
}
 .el-select-dropdown__item.selected{
  color: #1dbf73;
}
::v-deep .el-icon-arrow-up:before{
  color: #1dbf73;
}
.tips-demo {
  width: 1024px;
  margin: 0 auto;
  font-size: 12px;
  color: #999;
  padding: 20px 0;
  span {
    color: #f56c6c;
  }
}

@media only screen and (max-width: $mobile-width) {
  .contactus-us {
    height: 5.91rem;
    padding-top: 2.4rem;
    .contactus-us-view {
      width: 100%;
      padding-top: 0;
      .contactus-us-infor {
        h2 {
          font-size: 0.42rem;
          margin-bottom: 0.36rem;
        }
        h4 {
          font-size: 0.24rem;
        }
      }
    }
  }
  .cooperation-Subup {
    padding: 0.55rem 0 0.91rem 0;
    .cooperation-form {
      width: 100%;
      height: auto;
      .flexbox {
        display: block;
        padding-top: 0.55rem;
        .cooperation-item {
          width: 100%;
          padding-left: 0.45rem;
          padding-right: 0.45rem;
          padding-top: 0;
          margin: 0;
          .cooperation-item  {
            padding-left: 0;
           padding-right: 0;

          }
          .mt50 {
        transform: translateY(0);

      }
          .public-title {
            padding-top: 0.18rem;
            font-size: 0.25rem;
            margin-bottom: 0.18rem;
          }
          .public-select {
            width: 100% !important;
            min-height: 0.58rem !important;
            line-height: 0.58rem;
            margin-bottom: 0.18rem;
            font-size: 0.22rem;
            padding-left: 0.27rem;
            padding-right: 0.27rem;
            border-radius: 0.09rem;
            padding: 0;
          }
          .public-input {
            width: 100%;
            height: 0.58rem;
            line-height: 0.58rem;
            margin-bottom: 0.18rem;
            font-size: 0.22rem;
            padding-left: 0.27rem;
            padding-right: 0.27rem;
            border-radius: 0.09rem;
          }
          .public-textarea {
            width: 100%;
            height: 2rem;
            line-height: 0.36rem;
            margin-bottom: 0.18rem;
            font-size: 0.22rem;
            padding: 0.18rem 0.27rem;
            border-radius: 0.09rem;
          }
        }
      }
      .cooperation-button {
        width: 86.4%;
        height: 0.65rem;
        line-height: 0.65rem;
        margin-top: 0.42rem;
        font-size: 0.25rem;
      }
    }
  }
::v-deep .el-input__inner{
  height: 0.58rem !important;
}
::v-deep .el-select__tags {
  flex-wrap: nowrap;
  overflow-x: auto;
}

  .tips-demo {
    width: 100%;
    font-size: 0.18rem;
    padding-left: 0.45rem;
  }
}
</style>
